export const environment = {
  production: true,
  beta: false,
  gestioneconsorzi: false,
  ApiDomain: 'https://api.csoftonline.it/', //'http://localhost:19549/',
  TransDomain: 'https://translation.csoftonline.it/', //'http://localhost:14651/',//'http://translation.csoftbeta.it/',
  currentLevel: 'manager.csoftonline.it',
  domain: 'manager.csoftonline.it',
  subDomain: 'csoftonline.it',
  servicesDomain: 'https://services.csoftonline.it/it/', //services
  ReportDomain: 'https://report.csoftonline.it/', //'http://report.csoftbeta.it/',//'http://localhost:2079/', //
  api_lang: 'it',
  isEspana: false,
  redirectUrl: 'https://carview.csoftonline.it/',
  protocol: 'https:',
  MediaDomain: 'https://media.csoftonline.it/', //'http://localmedia.csoftonline.it/',
  AmministrazioneDomain: 'https://amministrazione.csoftonline.it/',
  ControllerDomain: 'https://controller.csoftonline.it/',
  ArDomain: 'https://areariservata.csoftonline.it/',
  DgDomain: 'https://dealergroup.csoftonline.it/',
  EnableUserTracking: true,
};
